<template>
  <div id="form"></div>
</template>
<script>
/**
 * 发送请求跳转到官方 支付宝收银（空白页必须有）
 */

export default {
  data() {
    return {};
  },
  watch: {},
  created() {
  },
  mounted() {
    this.payData = JSON.parse(localStorage.getItem("payData"));
    if (this.payData.code) {
      this.$message.error(this.payData.msg || "系统错误-100001");
      this.$router.replace("/confirm");
      return;
    }
    let form = document.getElementById("form");
    form.innerHTML = this.payData.content;
    let forms = document.getElementsByName("punchout_form");
    console.log(forms[0]);
    forms[0].submit();
  },
  methods: {},
};
</script>

<style>
.house-footer {
  display: none !important;
}

.cson-container {
  display: none !important;
}
</style>
